/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import {
  GenericDataType,
  JsonObject,
  StrictJsonValue,
} from '@superset-ui/core';
import { ControlFormItemSpec } from '@superset-ui/chart-controls';
import {
  SHARED_COLUMN_CONFIG_PROPS,
  SharedColumnConfigProp,
} from './constants';
import { ControlFormItemComponents } from './ControlForm';

/**
 * Column formatting configs.
 */
export type ColumnConfig = {
  // eslint-disable-next-line prettier/prettier
  [key in SharedColumnConfigProp]?: typeof SHARED_COLUMN_CONFIG_PROPS[key]['value'];
} & Record<string, StrictJsonValue>;

/**
 * All required info about a column to render the
 * formatting.
 */
export interface ColumnConfigInfo {
  name: string;
  type?: GenericDataType;
  config: JsonObject;
}

export type ControlFormItemDefaultSpec = ControlFormItemSpec<
  keyof typeof ControlFormItemComponents
>;

export type ColumnConfigFormItem =
  | SharedColumnConfigProp
  | {
      name: SharedColumnConfigProp;
      override: Partial<ControlFormItemDefaultSpec>;
    }
  | { name: string; config: ControlFormItemDefaultSpec };

export type TabLayoutItem = { tab: string; children: ColumnConfigFormItem[][] };

export type ColumnConfigFormLayout = Record<
  GenericDataType,
  ColumnConfigFormItem[][] | TabLayoutItem[]
>;

export function isTabLayoutItem(
  layoutItem: ColumnConfigFormItem[] | TabLayoutItem,
): layoutItem is TabLayoutItem {
  return !!(layoutItem as TabLayoutItem)?.tab;
}

export default {};
